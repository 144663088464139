.screen_container {
  width: 100%;
  margin: 0;
  padding: 0;
  min-height: 90vh;
  max-width: 100%;
}
/* File Drop Zone */
.dropzone {
  background-color: #cfc;
  border: solid 3px #9c9;
  color: #9c9;
  min-height: 50px;
  padding: 20px;
  text-shadow: 1px 1px 0 #fff;
}
.items:empty::before {
  color: #ccc;
  content:"(File counts will be shown here.)";
}
/*  */

i {
  margin: auto;
}
.landingPage {
  /* position: absolute; */
  min-height: fit-content;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}
.landingPage video {
  
  background-color: #3d5aaf;
  /* background-image: url(/frontend/src/assets/coding_background.png); */
  /* top: 0;
  
  z-index: -1; */
  /* position: absolute; */
  object-fit: cover;
  width: 100%;
  min-height: 90vh;
  margin: auto;
  /* background-color: #3d5aaf; */
}
.info_slide_container {
  overflow: hidden;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 25%;
}
.info_slide {
  padding: 15px;
  margin-left: 15px;
  color: white;
}
.info_slide h3 {
  padding: 0 0 15px 0;
  border-bottom: 1px solid white;
}
.topBar {
  background-color: #3d5aaf;
  height: 85px;
}

.getStarted {
  z-index: 10;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0;
  background-color: #3d5aaf;
  width: 100%;
  overflow: hidden;
}
@keyframes bounce { 
  0% { transform: translateY(0); }
  50% { transform: translateY(-10px);}
  100% { transform: translateY(0); }
}
 
.bounce {
  animation: bounce 0.4s;
}

.getStarted p {
 color: red important!;
 animation: bounce 0.4s;
 animation-delay: 1s;
}




.getStarted_submitform{
  background-color: rgba(167, 166, 166, 0.455);
  display:inherit;
  /* max-width: 375px; */
  min-width: 275px;
  max-height: 100%;
  /* width: 40%; */
  bottom: 20px;
  right: -1000px;
  float: right;
  border-radius: 7px;
  animation: pop 300ms linear 2s 1 forwards;
  position: absolute;
}
@keyframes pop {
  from {
     right: -1000px;
    }
  to {
    display: inline-block;
    right: 5px;
  }
}
.getStarted_container {
  width: 100%;
  max-width: 100%;
}


.landing_Container h1 {
  margin: 0;
}
.landing_featuredContainer {
  padding: 30px 0;
}
.textBanner {
  max-width: 100%;
  padding: 20px 0;
}

.firstCards_container {
  display: flex;
}
.firstCards_container h2 {
  font-size: 1.5rem;
  color: #333;
  padding: 5px 0;
}
.firstCards_container p {
  color: #333;
  padding-top: 5px;
  font-size: 1.2rem;
}

.infoSection_container {
  display: flex;
  padding: 50px 0;
  overflow: hidden;
}
.card_container {
  flex: 1;
  height: 20%;
  width: auto;
}
.miniform_container {
  margin: auto;
  max-width: 700px;
}
.infoSection_right {
  flex: 3;
  text-align: left;
}
.infoSection_container img {
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

.infoSection_container h3,
.infoSection_container h2,
.infoSection_container p {
  padding: 0 10px;
  
}

.infoSection_container h3 {
  color: #3d5aaf;
  font-size: 1.3rem;
}
.infoSection_right h2 {
  padding-bottom: 20px;
}
.infoSection_container p {
  font-size: 1.2rem;
}

@media (max-width: 980px) {

  .firstCards_container {
    display: flex;
    padding: 0 30px;
    flex-direction: column;
  }
  .card_container {
    display: none;
  }
  .infoSection_right {
    padding: 0 20px;
  }
}
@media (max-width: 440px) {

  @keyframes pop {
    from { right: -1000px;}
    to {right: 0px;}
  }
  .landingPage video {
   
    min-height: 75vh;
    
  }

  .info_slide {
    padding: 10px;
    margin-left: 5px;
  }
  .info_slide h3 {
    font-size: 1.3rem;
    padding: 0 0 10px 0;
  }

  .getStarted_submitform {
    width: 100%;
    min-width: 10px;
  }

  .firstCards_container {
    padding: 0;
  }
  .infoSection_container h2 {
    font-size: 1.7rem;
  }
  .infoSection_container h3 {
    font-size: 1.2rem;
  }
  .infoSection_container p {
    font-size: 1.1rem;
  }
}

@media (max-height: 700px) {

  .topBar {
    height: 75s;
  }
  .info_slide {
    padding: 10px;
    margin-left: 5px;
    
  }
  
  .info_slide h3 {
    display: none;
    /* font-size: 1.5rem;
    padding: 0 0 10px 0; */
  }
}
@media (max-height: 400px) {
  .getStarted {
    font-size: 1rem;
    background-color: rgba(0.1, 0.1, 0.1, 0.2);
  }
  .info_slide h3 {
    font-size: 1.2rem;
  }
}
