.screen_container {
  width: 100%;
  margin: 0;
  padding: 80px 0 0 0;
  min-height: 90vh;
}
.blogs_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
