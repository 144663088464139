.screen_container {
  width: 100%;
  margin: 0;
  padding: 0;
  min-height: 90vh;
}

.section_container {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
}
.imageBox_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 200px;
  height: 250px;
  padding: 20px;
  margin: auto;
}
