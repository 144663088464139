.screen_container {
  width: 100%;
  margin: 0;
  /* padding: 80px 0 0 0; */
  /* min-height: 90vh; */
}
.redMessage {
  text-align: center;
  color: rgb(134, 0, 0);
}
.section_container {
  display: flex;
  flex-direction: column;
}

.info_container {
  display: flex;
  flex-direction: row;
  margin: auto;
  max-width: 100%;
}
.icon_container {
  display: inline-flex;
  align-items: center;
  padding: 15px 0;
  max-width: 100%;
  overflow: hidden;
}

.icon_container h3 {
  text-align: right;
  padding: 0 7px;
  max-width: 100%;
}
.icon {
  padding: 0 20px;
}

.label {
  color: #4bb781;
}

.card_details {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 980px) {
  .section_container {
    flex-direction: column;
  }

  .info_container {
    display: flex;
    flex-direction: row;
  }
}
@media (max-width: 665px) {
  .info_container {
    display: flex;
    flex-direction: column;
  }
  .info_container h3 {
    font-size: 1.5rem;
  }
}
@media (max-width: 340px) {
  .info_container {
    display: flex;
    flex-direction: column;
  }
  .info_container h3 {
    font-size: 1rem;
  }
}
