.article_container {
  position: relative;
  width: 300px;
 height: fit-content;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  margin: 20px 40px;
}

.article_container p {
  width: 95%;
  padding: 0;
  margin: 0;
  max-height: 100px;
  overflow: hidden;
}
.article_container h3 {
  text-align: center;
  margin: auto;
 
}
.article_container a {
  text-decoration: none;
  color: #3d5aaf;
  float: right;
  font-size: 1.1em;
  text-align: right;
  bottom: -20px;
  max-width: 100%;
  padding: 5px 0 0 0;
}

.image_clipper {
  position: relative;
  max-width: 300px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.preview_image {
  text-align: center;
  width: 100%;
  height: auto;
  margin: auto;
  left: 0;
  top: 0;
  overflow: hidden;
}
.preview_content {
  top: 250px;
  max-height: 100%;
  left: 0;
}

@media (max-width: 320px) {
  .article_container {
    
    padding: 0 7px;
    max-width: 100%;
    /* height: 375px; */
    /* max-height: 375px; */
  }
  .article_container p {
    padding: 5px;
  }
  .article_container a {
    padding: 5px;
    bottom: 60px
  }
  

 
 
  .preview_image {
    text-align: center;
  }

  .preview_content h3 {
    display: none;
  }
  /* .preview_content {
    top: 180px;
  } */
}
@media (max-width: 270px) {
  .article_container a {
    /* bottom: 100px */
  }
  /* .preview_content {
    top: 160px;
  } */
}
@media (max-width: 230px) {
  .image_clipper {
    max-width: 300px;
    width: 100%;
    height: 40%;
    overflow: hidden;
  }
  /* .preview_content {
    top: 100px;
  } */
  .article_container a {
    /* bottom: 160px */
  }
}
@media (max-width: 200px) {
  .preview_content {
    top: 80px;
  }
}

