.screen_container {
  width: 100%;
  margin: 0;
  padding: 80px 0 0 0;
  min-height: 90vh;
}

.post_container {
  text-align: left;
  padding: 30px 20px;
  max-width: 95%;
}
.images_container {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}
.course_image {
  max-height: 200px;
  width: auto;
  padding: 10px;
}

.post_container p,
.post_container ol {
  font-size: 1.5rem;
  line-height: 65px;
}

.post_container h1 {
  padding: 20px 0;
}
.post_container code {
  background-color: lightgrey;
  margin: auto;
  float: left;
}

.post_container ul {
  font-size: 1.3rem;
}
@media (max-width: 440px) {
  .post_container {
    padding: 30px 10px;
  }

  .post_container h1 {
    font-size: 2.2rem;
  }

  .post_container h2 {
    font-size: 1.7rem;
  }
  .post_container h3 {
    font-size: 1.2rem;
  }
  .post_container p {
    font-size: 1.4rem;
    line-height: 55px;
  }
}
