.button_container {
  display: flex;
  flex-direction: row;
  z-index: 10000;
}
.popup_container {
  width: 80%;
  text-align: left;
z-index: 200;
}
