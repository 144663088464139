/*====================
          CARROUSEL
  ======================*/

.carrousel_wrapper {
  height: 700px;
  width: '1000px';
}

.carrousel_image {
  background-size: cover !important;
}
.slider_container {
  width: 70%;
}
.image {
  width: 100%;
  max-width: 200px;
}
@media (max-width: 980px) {
  .slider_container {
    width: 100%;
  }
}
