.screen_container {
  width: 100%;
  margin: 0;
  padding: 80px 0 0 0;
  min-height: 90vh;
}
.divMain {
  display: flex;
  padding: 50px 0;
  min-width: 300px;
}

.divMain a {
  font-size: 1.2rem;
}
.text_container {
margin: auto;
    display: flex;
    max-width: 300px;
    min-width: 300px;
    flex-direction: column;
}
.link {
  text-decoration: none;
  color: #3D5AAF;
   word-wrap: break-word;
}
.link_two {
    margin: auto;
    text-align: center;
    text-decoration: none;
    color: #3D5AAF;
  }
  .description {
    font-size: 1rem;
  }

.label {
   margin: -30px 0 0 0;
  font-size: 1.5rem;
  max-width: 300px;
  min-width: 300px;
}

@media (max-width: 980px) {
  .divMain {
    flex-direction: column;
  }
  .divMain h2 {
    font-size: 1.3rem;
  }
  .divMain a {
    font-size: 1.1rem;
  }
.label {
  font-size: 1.3rem;
    min-width: 0;
}
  .text_container {
      margin: auto;
}
.link_two {
  font-size: 1.1rem;

}
}
@media (max-width:440px) {
  .label {
    font-size: 1.1rem;
  }
  .link_two {
    font-size: .9rem;
  }
  .description {
    font-size: .9rem;
  }
}