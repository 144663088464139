.screen_container {
  width: 100%;
  margin: 0;
  padding: 80px 0 0 0;
  min-height: 90vh;
}

.section_container {
  display: flex;
  align-items: center;
}

.card_container {
  flex: 1;
  height: 20%;
  width: auto;
}
.section_right {
  flex: 3;
  text-align: left;
}

.section_container p {
  font-size: 1.2rem;
}
.section_container img,
.section_container img {
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}
.section_container h2,
.section_container h2,
.section_container p {
  padding: 0 10px;
}

.section_right h2 {
  color: black;
  padding-bottom: 20px;
}

@media (max-width: 980px) {
  .card_container {
    display: none;
  }
  .section_container {
    padding: 0 30px;
  }
}

@media (max-width: 440px) {
  .section_container {
    display: flex;
    flex-direction: column;
    padding: 0 5px;
  }
  .section_container h2 {
    font-size: 1.7rem;
  }
  .section_container h3 {
    font-size: 1.2rem;
  }
  .section_container p {
    font-size: 1.1rem;
  }
}
