.screen_container {
  width: 100%;
  margin: 0;
  padding: 0;
  min-height: 90vh;
}
.screen_container h2 {
  text-align: left;
  padding: 0 20px;
}
