.popup_container {
  width: 100%;
  height: 100%;
  margin: auto;
  z-index: 110;
}
.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: calc(50% - 150px);
  right: calc(50% - 30vw);
  margin: auto;
  width: 60vw;
  height: 400px;
  background-color: white;
  z-index: 111;
  padding: 10px;
}
.closeButton {
  display: flex;
  position: fixed;
  margin: auto;
  align-items: center;
  justify-content: center;
  z-index: 111;
  top: calc(50% - 200px + 30px);
  right: calc((50% - 30vw) - 15px);
  width: 30px;
  height: 30px;
  background-color: lightgrey;
  border-radius: 50%;
  box-shadow: 0px 5px 10px grey;
}
.closeButton:hover {
  cursor: pointer;
}
.button_container {
  display: flex;
  flex-direction: row;
  text-align: left;
  width: 80%;
}

@media (max-width: 600px) {
  .body {
    top: calc(25% + 112.5);
    right: 0;
    width: 100%;
    height: 40%;
    min-height: 450px;

    padding: 7px;
  }
  .closeButton {
    right: 1%;
    width: 40px;
    height: 40px;
  }
}
