.screen_container {
  width: 100%;
  margin: 0;
  padding: 0;
  min-height: 90vh;
}

.imageBox_container {
  display: inline;
}
