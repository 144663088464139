.label {
  position: absolute;
  top: 50%;
  margin: 0 20px;
  color: #fff;
  font-size: 2.5rem;
  z-index: 1;
}
@media (max-width: 980px) {
  .label {
    font-size: 2rem;
  }
}
