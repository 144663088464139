h2,
h3,
h1 {
  font-family: 'Source Code Pro';
}
p,
button,
ul {
  font-family: 'Inter';
}
a:hover {
  text-decoration: none;
}
