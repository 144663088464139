.screen_container {
  width: 100%;
  margin: 0;
  padding: 0;
  min-height: 90vh;
}

.horizontal_div {
  display: flex;
  justify-content: center;
  align-items: center;
}
